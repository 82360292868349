import React from "react";
import ReactDOM from "react-dom";
import RoutesLek from "./routes-lek.js";
import store from "./store.js";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/custom.css";
import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL);

function WebApp() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <RoutesLek component={RoutesLek} />
      </Provider>
    </BrowserRouter>
  );
}

ReactDOM.render(<WebApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Suspense, lazy } from "react";
import { Router, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router";
import { connect } from "react-redux";
import lazyRetry from "./utility/lazyRetry";
import { history } from "./managers/history";
import LoadingScreen from "./common/components/loadingScreen";
import { Toaster } from "react-hot-toast";

const Login = lazy(() =>
  lazyRetry(() => import("./modules/lek/modules/login/index"))
);
const Dashboard = lazy(() =>
  lazyRetry(() => import("./modules/lek/modules/dashboard/index"))
);
const Error = lazy(() =>
  lazyRetry(() => import("./modules/lek/modules/common/error"))
);
const Callback = lazy(() =>
  lazyRetry(() => import("./modules/lek/modules/common/callback"))
);
const RoutesLek = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Route exact path={"/profile"} component={Login} />
          <Route exact path={"/dashboard"} component={Dashboard} />
          <Route exact path={"/callback"} component={Callback} />
          <Route exact path={"/error"} component={Error} />
          <Redirect exact from="*" to="/profile" />
        </Switch>
      </Suspense>
      <Toaster
        toastOptions={{
          className: "alert-toast font-PoppinsMedium",
          style: {
            borderRadius: "16px",
          },
        }}
      />
    </Router>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(RoutesLek);

import { history } from "../managers/history";
import swal from "sweetalert";
import ShowToast from "../common/components/showToast";
import { toastType } from "../constants";

const moment = require("moment");

const utility = {
  getHeader,
  apiFailureToast,
  apiSuccessToast,
  generateGUID,
  basicAlert,
  validationAlert,
  isNumber,
  emailShortner,
  navigateToPath,
  toggleDropDown,
  validateName,
  validateEmail,
  isEmpty,
  isMenuActive,
  isPasswordValid,
  showUnderDevelopment,
  epochToDate,
  dateCompare,
  tokenShorten,
  getTimeFromNow,
  epocToPrettyTime,
  getTimeDifference,
  parseResponse,
  storeToLocalStorage,
  getDataFromLocalStorage,
  addDays,
  generateScript,
  capitalizeFirstLetter,
  formatDate,
  formatSessionTime,
  shorten,
  objectsAreEqual,
  emailShorten,
  formatDateTime,
  convertDecimal,
  createWidgetScript,
  companyNameShortner,
  generateUrl,
  getHistoryTimestamp,
};
export default utility;

export const dispatchAction = (type, data) => {
  return (dispatch) => dispatch({ type, data });
};

function getHeader() {}

function storeToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function generateUrl(url, params) {
  let i = 0,
    key;
  for (key in params) {
    if (i === 0) {
      url += "?";
    } else {
      url += "&";
    }
    url += key;
    url += "=";
    url += params[key];
    i++;
  }
  return url;
}

function getHistoryTimestamp(timestamp) {
  if (moment(timestamp).fromNow() === "a few seconds ago") {
    return "Just now";
  } else return moment(timestamp).fromNow();
}

function companyNameShortner(value, amountL = 12, amountR = 9, stars = 2) {
  if (typeof value === "string" && value.length > amountL + amountR) {
    return shortenString(value, amountL, amountR, stars);
  }
  return value;
}

function getDataFromLocalStorage(key) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}
function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function parseResponse(promise) {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => [err]);
}

function apiFailureToast(message) {
  ShowToast({
    message: message ? message : "apiConstant.API_FAILURE",
    type: toastType.ERROR,
  });
}

function apiSuccessToast(msg) {
  ShowToast({
    message: msg ? msg : "apiConstant.API_SUCCESS",
    type: toastType.SUCCESS,
  });
}

function generateGUID() {
  const nav = window.navigator;
  const screen = window.screen;
  let guid = String(nav.mimeTypes.length);
  guid += `${nav.userAgent.replace(/\D+/g, "")}`;
  guid += `${nav.plugins.length}`;
  guid += `${screen.height || ""}`;
  guid += `${screen.width || ""}`;
  guid += `${screen.pixelDepth || ""}`;

  return guid;
}

function basicAlert(message) {
  swal({
    title: message,
    icon: "/images/alert-icon.png",
  });
}

function validationAlert(message, type = "info") {
  swal({
    title: message,
    icon: type,
  });
}

function getTimeDifference(timeStampTo) {
  let minFive = 300000;
  let oneDay = 86400000;
  let difference = "";
  let am = " AM";
  let pm = " PM";
  let hh = epochToDate(timeStampTo, "hh");
  let mm = epochToDate(timeStampTo, "mm");
  let dateFormat = epochToDate(timeStampTo, "DD MMM YYYY");
  let hours = new Date(timeStampTo).getHours();
  let timeDifference = new Date().getTime() - timeStampTo;
  if (timeDifference < oneDay) {
    if (timeDifference < minFive) {
      difference = "Just Now";
    } else {
      if (hours < 12) difference = "Today at " + hh + ":" + mm + am;
      else difference = "Today at " + hh + ":" + mm + pm;
    }
  } else {
    if (hours < 12) difference = dateFormat + ", " + hh + ":" + mm + am;
    else difference = dateFormat + ", " + hh + ":" + mm + pm;
  }
  return difference;
}

function epochToDate(timeStamp, timeFormat) {
  timeStamp = Math.floor(timeStamp); //to convert to integer if seconds is String.
  let dateObject = new Date(timeStamp);
  return moment(dateObject).format(timeFormat); //DD MMM YYYY
}

function getTimeFromNow(timeStamp) {
  return moment(timeStamp, "YYYYMMDD").fromNow();
}

function dateCompare(timeStampFrom, timeStampTo) {
  return (timeStampFrom * 1000 - timeStampTo) / (1000 * 60 * 60 * 24);
}

function addDays(date, days) {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
}

function navigateToPath(path) {
  history.push(path);
}

function toggleDropDown(dropdownID) {}

function validateName(name) {
  let reg = /[A-Z][a-zA-Z]*/;
  return reg.test(name);
}

function validateEmail(email) {
  let reg =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

function isPasswordValid(password) {
  // eslint-disable-next-line
  let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return reg.test(password);
}

function isEmpty(string) {
  return !string || string.trim().length === 0;
}

function isMenuActive(path) {
  return window.location.pathname.includes(path);
}

function showUnderDevelopment() {
  basicAlert("Under Development");
}

function capitalizeFirstLetter(str) {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function epocToPrettyTime(seconds) {
  seconds = Math.floor(seconds);
  const nowTimeMilliseconds = new Date().getTime();
  const date = new Date(seconds);
  const dateObject = moment(date).format("DD MMMM YYYY");
  seconds = Math.floor(nowTimeMilliseconds / 1000 - seconds / 1000);
  let interval = Math.floor(seconds / 172800);
  if (interval >= 1) return dateObject;
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return "Yesterday";

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    if (interval === 1) return interval + " hour ago";
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    if (interval === 1) return interval + " minute ago";
    return interval + " minutes ago";
  } else return "Just now";
}

function emailShortner(value, amountL = 17, amountR = 18, stars = 3) {
  if (typeof value === "string" && value.length > amountL + amountR) {
    return shortenString(value, amountL, amountR, stars);
  }
  return value;
}

function generateScript(url) {
  return `<script>
  document.addEventListener("DOMContentLoaded", function () {
    var image = document.createElement("img");
    var chatIcon =
      "https://d3tfuasmf2hsy5.cloudfront.net/assets/chatwidget.png";
    var closeIcon =
      "https://d3tfuasmf2hsy5.cloudfront.net/assets/closewidgget.png";
    image.src = chatIcon;
    image.style.width = "80px";
    image.style.cursor = "pointer";
    image.style.borderRadius = "100%";
    image.style.position = "fixed";
    image.style.bottom = "20px";
    image.style.right = "20px";
    image.style.zIndex = "1000";

    var iframe = document.createElement("iframe");
    iframe.src =
      "${url}"
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "1px solid #DEDEDE";
    iframe.style.borderRadius = "16px";
    iframe.style.display = "none";
    iframe.style.zIndex = "1000";

    function toggleIframe() {
      if (iframe.style.display === "none") {
        iframe.style.display = "block";
        image.src = closeIcon;
      } else {
        iframe.style.display = "none";
        image.src = chatIcon;
      }
    }

    image.addEventListener("click", toggleIframe);

    var container = document.createElement("div");
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    container.style.position = "fixed";
    container.style.height = "600px";
    container.style.bottom = "100px";
    container.style.zIndex = "1000";

    if (viewportWidth < 450) {
      container.style.width = "100%";
      container.style.paddingRight = "10px";
      container.style.paddingLeft = "10px";
    } else {
      container.style.right = "20px";
      container.style.width = "450px";
    }

    container.appendChild(image);
    container.appendChild(iframe);

    document.body.appendChild(container);
  });
</script>`;
}

function encodeText(text) {
  return btoa(text);
}

function createWidgetScript(obj) {
  const appHost = window.location.host;
  const serviceHost = new URL(process.env.REACT_APP_CONTENT_MICROSERVICE_URL)
    .host;
  const zbrainApp = encodeText(`${appHost}&&${serviceHost}`);
  return `<script type="text/javascript">
  window.ZBRAIN_APP = "${zbrainApp}";
  window.ZBRAIN_TENANT_ID = "${obj.tenantId}";
  window.ZBRAIN_WIDGET_ID = "${obj.appId}";
  window.QUERY_STRING_PARAMETERS = "";
  (function () {
    d = document;
    s = d.createElement("script");
    s.src = "${window.location.origin}/script.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
</script>`;
}

function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year}, ${hours}:${minutes}`;
}

function formatSessionTime(timestamp) {
  const dateObj = new Date(timestamp);
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    // year: "numeric",
  });
  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  const formattedTimestamp = `${formattedDate} ${formattedTime}`;
  return formattedTimestamp;
}

function objectsAreEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

function shortenString(value, amountL, amountR, stars) {
  return `${value.slice(0, amountL)}${".".repeat(stars)}${value.slice(
    -amountR
  )}`;
}

function shorten(value, amountL = 14, amountR = 12, stars = 4) {
  if (typeof value === "string" && value.length > amountL + amountR) {
    return shortenString(value, amountL, amountR, stars);
  }
  return value;
}

function emailShorten(value, amountL = 10, amountR = 11, stars = 3) {
  if (typeof value === "string" && value.length > amountL + amountR) {
    return shortenString(value, amountL, amountR, stars);
  }
  return value;
}
function tokenShorten(value, amountL = 35, amountR = 10, stars = 3) {
  if (typeof value === "string" && value.length > amountL + amountR) {
    return shortenString(value, amountL, amountR, stars);
  }
  return value;
}

function formatDateTime(timestamp) {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedDate = `${day} ${month} ${year}, ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;
  return formattedDate;
}

function convertDecimal(value) {
  if (value % 1 === 0) {
    return value;
  } else {
    const formattedValue = parseFloat(value.toFixed(2));
    return formattedValue;
  }
}

import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";

function LoadingScreen() {
  return (
    <div className="grid place-content-center w-full bg-transparent min-h-screen">
      <BiDotsHorizontalRounded className="animate-bounce mx-auto w-20 h-20 text-green-00B852" />
    </div>
  );
}

export default LoadingScreen;

let initialState = {
  selectedBot: sessionStorage.getItem("selectedBot"),
  botLogo: "",
  welcomeMessage: "",
  allBots: [],
  sidebar: false,
  industryCode: "",
  botChat: [],
  appId: "",
  sessionId: "",
  onCd: "",
  queryHistory: [],
  commonLoading: false,
  historyLoading: true,
  chatLoading: false,
  token: sessionStorage.getItem("token"),
  companyName: "",
  activeSession: "",
};

export const selectActiveBot = (data) => {
  return {
    type: "botSelection",
    payload: data,
  };
};

export const addBots = (data) => {
  return {
    type: "allBots",
    payload: data,
  };
};

export const handleSidebar = (data) => {
  return {
    type: "sidebar",
    payload: data,
  };
};

export const handleIndustryCode = (data) => {
  sessionStorage.setItem("industryCode", JSON.stringify(data));
  return {
    type: "industryCode",
    payload: data,
  };
};

export const handleBotChat = (data) => {
  return {
    type: "updateChat",
    payload: data,
  };
};

export const handleAppId = (data) => {
  sessionStorage.setItem("appId", data);
  return {
    type: "updateAppId",
    payload: data,
  };
};

export const updateActiveSession = (data) => {
  return {
    type: "updateActiveSession",
    payload: data,
  };
};

export default function userState(state = initialState, action) {
  switch (action.type) {
    case "botSelection":
      return {
        ...state,
        selectedBot: action.payload,
      };
    case "allBots": {
      return {
        ...state,
        allBots: action.payload,
      };
    }
    case "sidebar": {
      return {
        ...state,
        sidebar: action.payload,
      };
    }
    case "industryCode": {
      return {
        ...state,
        industryCode: action.payload,
      };
    }
    case "updateChat": {
      let updatedChat = [...state.botChat];
      updatedChat.push(action.payload);
      return {
        ...state,
        botChat: updatedChat,
      };
    }
    case "updateMessage": {
      return {
        ...state,
        welcomeMessage: action.payload,
      };
    }
    case "updateLogo": {
      return {
        ...state,
        botLogo: action.payload,
      };
    }
    case "updateAppId": {
      return {
        ...state,
        appId: action.payload,
      };
    }
    case "updateSession": {
      return {
        ...state,
        sessionId: action.payload,
      };
    }
    case "updateCd": {
      return {
        ...state,
        onCd: action.payload,
      };
    }
    case "SELECT_CD": {
      return {
        ...state,
        onCd: state.onCd === action.payload ? "" : action.payload,
      };
    }
    case "updateQueries": {
      let updatedQueries = [
        ...state.queryHistory,
        ...action.payload.map((query) => query),
      ];
      return {
        ...state,
        queryHistory: updatedQueries,
      };
    }
    case "clearChat": {
      return {
        ...state,
        botChat: [],
      };
    }
    case "loading": {
      return {
        ...state,
        commonLoading: action.payload,
      };
    }
    case "historyLoading": {
      return {
        ...state,
        historyLoading: action.payload,
      };
    }
    case "chatLoading": {
      return {
        ...state,
        chatLoading: action.payload,
      };
    }
    case "setToken": {
      return {
        ...state,
        token: action.payload,
      };
    }
    case "updateCompanyName": {
      return {
        ...state,
        companyName: action.payload,
      };
    }

    case "updateActiveSession": {
      return {
        ...state,
        activeSession: action.payload,
        sessionId: action.payload,
      };
    }
    case "UPDATE_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
